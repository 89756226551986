<template>
  <section class="section container section-events">
    <div class="section-events__header">
      <h2 class="section__title section-events__title">{{ $t('Our Events') }}</h2>

      <div class="section-events__nav">
        <button class="section-events__nav-prev swiper-slide-nav swiper-slide-nav-prev swiper-button-disabled" type="button" disabled=""><span class="visually-hidden">Prev slide</span></button>
        <button class="section-events__nav-next swiper-slide-nav swiper-slide-nav-next" type="button"><span class="visually-hidden">Next slide</span></button>
      </div>
    </div>

    <div class="swiper section-events__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
      <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
        <div class="swiper-slide section-events__slide swiper-slide-active" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-event-1.png" alt="slide 1" class="section-events__slide-img" data-fancybox="gallery-a" data-caption="Gallery A #1">
        </div>
        <div class="swiper-slide section-events__slide swiper-slide-next" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-event-2.png" alt="slide 2" class="section-events__slide-img" data-fancybox="gallery-a" data-caption="Gallery A #1">
          <img src="@/assets/images/landing/slide-event-3.png" alt="slide 3" class="section-events__slide-img" data-fancybox="gallery-a" data-caption="Gallery A #1">
        </div>
        <div class="swiper-slide section-events__slide" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-event-4.png" alt="slide 4" class="section-events__slide-img" data-fancybox="gallery-a" data-caption="Gallery A #1">
        </div>
        <div class="swiper-slide section-events__slide" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-1.png" alt="slide 1" class="section-events__slide-img" data-fancybox="gallery-a" data-caption="Gallery A #1">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper"
import {Grid, Navigation, Pagination, Thumbs} from 'swiper/modules'

export default {
  mounted() {
    let swiperEvents = new Swiper(".section-events__slider", {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 16,
      navigation: {
        nextEl: ".section-events__nav-next",
        prevEl: ".section-events__nav-prev",
      },
      breakpoints: {
        768: {
          spaceBetween: 32,
        }
      }
    });
  }
}
</script>
<!-- <script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class LndEvents extends Vue {}
</script> -->
