<template>
  <section class="section container section-office">
    <h2 class="section__title section-office__title">{{ $t('Welcome to Our Office') }}</h2>
    <div class="section-office__header">
      <address class="section-office__address">
        Conrad Business Tower, Office 1008 <br>
        Sheikh Zayed Road, Dubai, UAE <br>
        P.O. Box 30208
      </address>

      <div class="section-office__slider-nav">
        <button class="section-office__nav-prev swiper-slide-nav swiper-slide-nav-prev swiper-button-disabled" type="button" disabled=""><span class="visually-hidden">Prev slide</span></button>
        <button class="section-office__nav-next swiper-slide-nav swiper-slide-nav-next" type="button"><span class="visually-hidden">Next slide</span></button>
      </div>
    </div>

    <div class="swiper section-office__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
      <div class="swiper-wrapper" style="transform: translate3d(0px, 0px, 0px);">
        <div class="swiper-slide section-office__slide swiper-slide-active" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-1.png" alt="slide 1" class="section-office__slide-img" data-fancybox="gallery" data-caption="Gallery #1">
        </div>
        <div class="swiper-slide section-office__slide swiper-slide-next" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-2.png" alt="slide 2" class="section-office__slide-img" data-fancybox="gallery" data-caption="Gallery #1">
        </div>
        <div class="swiper-slide section-office__slide" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-3.png" alt="slide 3" class="section-office__slide-img" data-fancybox="gallery" data-caption="Gallery #1">
        </div>
        <div class="swiper-slide section-office__slide" style="margin-right: 32px;">
          <img src="@/assets/images/landing/slide-1.png" alt="slide 1" class="section-office__slide-img" data-fancybox="gallery" data-caption="Gallery #1">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper"
import {Grid, Navigation, Pagination, Thumbs} from 'swiper/modules'
export default {
  mounted() {
    let swiperOffice = new Swiper(".section-office__slider", {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 16,
      navigation: {
        nextEl: ".section-office__nav-next",
        prevEl: ".section-office__nav-prev",
      },
      breakpoints: {
        768: {
          spaceBetween: 32,
        }
      }
    });
  }
}
</script>

<!-- <script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class LndOffice extends Vue {}
</script> -->
