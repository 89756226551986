<template>
  <section class="section-subscribe section container">
    <div class="section-subscribe__content">
      <h2 class="section__title section-subscribe__title">
        {{ $t('subscribe_title') }}
      </h2>

      <Transition name="fade">
        <form
          v-if="!isSubscribed"
          action="https://login.sendpulse.com/forms/simple/u/eyJ1c2VyX2lkIjo4MTI5OTQyLCJhZGRyZXNzX2Jvb2tfaWQiOjYyMTYyMiwibGFuZyI6InJ1In0="
          method="post"
          class="form form-subscribe section-subscribe__form"
          @submit.prevent.stop="subscribe"
        >
            <label class="form__label">
              <span class="visually-hidden">Enter your Email</span>
              <input
                name="email"
                type="email"
                v-model="email"
                class="form-subscribe__input"
                :placeholder="$t('Enter your Email')"
                :disabled="isSubscribed"
              >
              <input type="hidden" name="sender" value="info@dexmobile.io">
            </label>

          <button
            class="button form__button"
            :disabled="isSubscribed"
          >
            <span>
              {{ $t('Subscribe') }}
            </span>
          </button>
        </form>
        <template v-if="isShowSuccess">
          <button
            class="button form__button"
          >
            <span>
              {{ $t('Subscribed') }} ✓
            </span>
          </button>
      </template>
    </Transition>


      <!-- <form action="" class="form form-subscribe section-subscribe__form">
        <label class="form__label">
          <span class="visually-hidden">Enter your Email</span>
          <input
            type="email"
            class="form-subscribe__input form-input-error"
            placeholder="Enter your email"
          >
          <span class="form-error">This email already in use</span>
        </label>

        <button class="button form__button">Subscribe</button>
      </form> -->
    </div>

<!--    <div class="section-subscribe__content">-->
<!--      <h2 class="section__title section-subscribe__title">-->
<!--    {{ $t('subscribe_title') }}-->
<!--      </h2>-->

<!--      <form action="" class="form form-subscribe section-subscribe__form">-->
<!--        <label class="form__label">-->
<!--          <span class="visually-hidden">Enter your Email</span>-->
<!--          <input-->
<!--            type="email"-->
<!--            class="form-subscribe__input"-->
<!--            placeholder="Enter your email"-->
<!--          >-->
<!--        </label>-->

<!--        <button class="button form__button">Subscribe</button>-->
<!--      </form>-->
<!--    </div>-->
  </section>
</template>

<script>
export default {
  name: 'LndSubscribe',
  data: () => ({
    isSubscribed: false,
    isShowSuccess: false,
    email: ''
  }),
  methods: {
    subscribe() {
      this.isSubscribed = true

      setTimeout(() => {
        this.isShowSuccess = true
      }, 500);

      const formData = new FormData()

      formData.append('email', this.email)
      formData.append('sender', 'info@dexmobile.io')

      fetch('https://login.sendpulse.com/forms/simple/u/eyJ1c2VyX2lkIjo4MTI5OTQyLCJhZGRyZXNzX2Jvb2tfaWQiOjYyMTYyMiwibGFuZyI6InJ1In0=', {
        method: 'POST',
        body: formData
      })
    }
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<!-- <script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class LndSubscribe extends Vue {}
</script> -->
